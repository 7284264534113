const pricingTable = {
  track: [
    { title: "Timer or add manually", free: true },
    { title: "Timesheet - weekly tracking ", free: true },
    { title: "Timer - daily tracking", free: true },
    { title: "Timeline view", free: true },
    { title: "Favorites", free: true },
    { title: "Expenses", free: true },
    { title: "Detailed description and attachments", free: true },
    { title: "Daily tracking reminder (mobile app)", free: true },
    { title: "Bulk edit or delete logs", free: false },
    { title: "Track custom fields (numeric)", free: false },
  ],

  organize: [
    { title: "Unlimited projects, tasks and clients", free: true },
    { title: "Task and project templates", free: true },
    { title: "Task and project descriptions", free: true },
    { title: "Group tasks and mark as dones", free: true },
    {
      title: "Budget hours, billable or cost amounts ",
      free: false,
      pro: true,
    },
    { title: "Budget alerts to email", free: false },
    { title: "Monthly budget", free: false },
    { title: "Task budgets or estimates", free: false },
    { title: "Assign tasks to team members", free: false },
  ],
  billing: [
    { title: "Billable rate per project", free: true },
    { title: "Billable rate per tasks or team member", free: false },
    { title: "Labor costs (Team costs) ", free: false },
    { title: "Calculate profit & loss", free: false },
    { title: "Auto-round billable time", free: false },
    { title: "Invoicing", free: false },
  ],
  report: [
    { title: "Dashboard", free: true },
    { title: "Customize a detailed report (Activity)", free: true },
    { title: "Download to PDF, export to XLS", free: true },
    { title: "Send reports to Email", free: true },
    { title: "Timesheet report", free: true },
    { title: "Schedule detailed reports to email", free: false },
    { title: "Team pivot report", free: false },
    { title: "Economy report", free: false },
    { title: "Download & customize reports in Excel", free: false },
    { title: "Own logo on PDF reports", free: false },
  ],
  team: [
    { title: "Unlimited team members", free: true },
    { title: "Role: Administrator", free: true },
    { title: "Role: Normal member ", free: true },
    { title: "Role: Project manager", free: false },
    { title: "Group members into Teams", free: false },
    { title: "Hide billing and cost details", free: false },
    { title: "Require project and task input ", free: false },
    { title: "Approval workflow", free: false },
    { title: "Add, edit or delete team's logs", free: false },
    { title: "Auto-lock time logs ", free: false },
    { title: "Tracking reminders", free: false },
    { title: "Audit log report", free: false },
    { title: "Track team's capacity", free: false },
  ],
  integration: [
    { title: "Import timesheets, projects and clients", free: true },
    { title: "Mobile apps", free: true },
    { title: "API access", free: true },
    { title: "Zapier integration", free: true },
    { title: "Quickbooks native integration", free: false },
  ],
};

export { pricingTable };
